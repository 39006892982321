export default {
  namespaced: true,
  state: {
    // f1表格入参变量
    v1Date: null,
    v2Date: null,
    v3Date: null,
    v4Date: null,
    v5Date: null,
    v6Date: null,
  },
  mutations: {
    setV1Date(state, val) {
      state.v1Date = val
    },
    setV2Date(state, val) {
      state.v2Date = val
    },
    setV3Date(state, val) {
      state.v3Date = val
    },
    setV4Date(state, val) {
      state.v4Date = val
    },
    setV5Date(state, val) {
      state.v5Date = val
    },
    setV6Date(state, val) {
      state.v6Date = val
    },

  }
}