import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
// 全局icon
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

const app = createApp(App);
// 全局icon
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// 国际化
app.use(ElementPlus, {
  locale: zhCn,
})
app.use(store);
app.use(router);
app.use(ElementPlus);

app.mount("#app");