export default {
  namespaced: true,
  state: {
    theme: [
      {
        name: "蓝绿主题",
        value: "#00ae9d",
        textColor: '#fff',
        activeTextColor: 'red'
      },
      {
        name: "蓝色主题",
        value: "#00a6ac",
        textColor: '#fff',
        activeTextColor: 'red'
      }
    ],
    current_theme: {
      colorName: "默认主题",
      backColor: "rgb(51,61,82)",
      textColor: "rgb(143,166,191)",
      activeTextColor: "rgb(58,156,108)",
    },
    // 三级字典
    dict1: [],
    // 二级字典
    dict2: [],
    // 路由
    currentPathData: [],
    // 初始化值
    initData: {
      beginTime: 2022,
      cityCode2: [420000, 420100],
      cityCode3: [420000, 420100, 420106],
    }

  },
  mutations: {
    setDict1(state, val) {
      state.dict1 = val
    },
    setDict2(state, val) {
      state.dict2 = val
    },
    setDict3(state, val) {
      state.currentPathData = val
    },
    setInitData(state, val) {
      state.initData = val
    },
  }
}