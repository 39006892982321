import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import {
  ElMessage
} from "element-plus";
import {
  decrypt
} from "@/utils/jsencrypt";

const routes = [

  {
    path: '/home',
    name: 'home',
    meta: {
      title: '文旅分析系统'
    },
    component: () => import('@/views/Home'),
    children: [
      // 可视化图
      {
        path: "Vsualization/V1",
        name: "Vsualization_V1",
        meta: {
          title: "投资额分布情况",
        },
        component: () => import("@/views/Home/Vsualization/V1"),
      },
      {
        path: "Vsualization/V2",
        name: "Vsualization_V2",
        meta: {
          title: "入库项目数量分布",
        },
        component: () => import("@/views/Home/Vsualization/V2"),
      },
      {
        path: "Vsualization/V3",
        name: "Vsualization_V3",
        meta: {
          title: "市投资完成情况",
        },
        component: () => import("@/views/Home/Vsualization/V3"),
      },
      {
        path: "Vsualization/V4",
        name: "Vsualization_V4",
        meta: {
          title: "项目增长情况",
        },
        component: () => import("@/views/Home/Vsualization/V4"),
      },
      {
        path: "Vsualization/V5",
        name: "Vsualization_V5",
        meta: {
          title: "推进综合评价指数",
        },
        component: () => import("@/views/Home/Vsualization/V5"),
      },
      {
        path: "Vsualization/V6",
        name: "Vsualization_V6",
        meta: {
          title: "综合评价指数画像",
        },
        component: () => import("@/views/Home/Vsualization/V6"),
      },
      {
        path: "Vsualization/V7",
        name: "Vsualization_V7",
        meta: {
          title: "评价指数进步排名",
        },
        component: () => import("@/views/Home/Vsualization/V7"),
      },
      {
        path: "Vsualization/V8",
        name: "Vsualization_V8",
        meta: {
          title: "项目投资进度图",
        },
        component: () => import("@/views/Home/Vsualization/V8"),
      },
      {
        path: "Vsualization/V9",
        name: "Vsualization_V9",
        meta: {
          title: "山区推进综合指数",
        },
        component: () => import("@/views/Home/Vsualization/V9"),
      },
      {
        path: "Vsualization/V10",
        name: "Vsualization_V10",
        meta: {
          title: "项目时间分布",
        },
        component: () => import("@/views/Home/Vsualization/V10"),
      },
      {
        path: "Vsualization",
        name: "Vsualization",
        meta: {
          title: "数据可视化",
        },
        component: () => import("@/views/Home/Vsualization"),
      },
      // 数据表
      {
        path: "Table",
        name: "Table",
        meta: {
          title: "数据报表",
        },
        component: () => import("@/views/Home/Table"),
      },
      {
        path: "Table/InvestmentGrowth",
        name: "Table_InvestmentGrowth",
        meta: {
          title: "投资增长情况表",
        },
        component: () => import("@/views/Home/Table/InvestmentGrowth"),
      },
      {
        path: "Table/InvestmentInfo",
        name: "Table_InvestmentInfo",
        meta: {
          title: "投资信息表",
        },
        component: () => import("@/views/Home/Table/InvestmentInfo"),
      },
      {
        path: "Table/IndexScore",
        name: "Table_IndexScore",
        meta: {
          title: "指数得分表",
        },
        component: () => import("@/views/Home/Table/IndexScore"),
      },
      {
        path: "Table/QualityRate",
        name: "Table_QualityRate",
        meta: {
          title: "优质率统计表",
        },
        component: () => import("@/views/Home/Table/QualityRate"),
      },
      {
        path: "Table/ProblemItems",
        name: "Table_ProblemItems",
        meta: {
          title: "问题项目投资信息表",
        },
        component: () => import("@/views/Home/Table/ProblemItems"),
      },
      {
        path: "Table/Comprehensive",
        name: "Table_Comprehensive",
        meta: {
          title: "综合评价指数表",
        },
        component: () => import("@/views/Home/Table/Comprehensive"),
      },
      {
        path: "Table/Keynote",
        name: "Table_Keynote",
        meta: {
          title: "重点项目统计表",
        },
        component: () => import("@/views/Home/Table/Keynote"),
      },
      // 报表填报
      {
        path: "Fill",
        name: "Fill",
        meta: {
          title: "",
        },
        component: () => import("@/views/Home/Fill"),
      },
      {
        path: "Fill/F1",
        name: "Fill_F1",
        meta: {
          title: "项目情况表",
        },
        component: () => import("@/views/Home/Fill/F1"),
      },
      {
        path: "Fill/F1/Check",
        name: "Fill_F1Check",
        component: () => import("@/views/Home/Fill/F1/Check"),
      },
      {
        path: "Fill/F2",
        name: "Fill_F2",
        meta: {
          title: "县级行政项目汇总表",
        },
        component: () => import("@/views/Home/Fill/F2"),
      },
      {
        path: "Fill/F3",
        name: "Fill_F3",
        meta: {
          title: "市州项目汇总表",
        },
        component: () => import("@/views/Home/Fill/F3"),
      },
      {
        path: "Fill/F4",
        name: "Fill_F4",
        meta: {
          title: "省外招商引资情况表",
        },
        component: () => import("@/views/Home/Fill/F4"),
      },
      {
        path: "Fill/F5",
        name: "Fill_F5",
        meta: {
          title: "项目用地情况一览表",
        },
        component: () => import("@/views/Home/Fill/F5"),
      },
      {
        path: "Fill/F6",
        name: "Fill_F6",
        meta: {
          title: "扣分项目情况",
        },
        component: () => import("@/views/Home/Fill/F6"),
      },
      {
        path: "Fill/F7",
        name: "Fill_F7",
        meta: {
          title: "投资商信息汇总表",
        },
        component: () => import("@/views/Home/Fill/F7"),
      },
      {
        path: "Fill/F7/Check",
        name: "Fill_F7Check",
        component: () => import("@/views/Home/Fill/F7/Check"),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '文旅分析系统'
    },
    component: () => import('@/views/Login')

  },
  {
    path: "/",
    redirect: "/home"
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 前置路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // 排除页面
  if (
    to.path == "/Login"
  ) {
    next();
    return false;
  }

  let loginMsg = localStorage.getItem("loginMsg")
  if (!loginMsg) {
    ElMessage.error("请先登录");
    next("/Login");
    return false;
  }
  let data = JSON.parse(decrypt(loginMsg))
  // 判断是否过期
  let late = ((Date.now() - data.nonce) / (1000 * 60 * 60 * 24)).toFixed(0)
  if (late >= 1) {
    localStorage.setItem("loginMsg", '');
    next("/Login");
    return false;
  }
  // 没有过期验证登录
  let roleSid = data.data.roleSid
  if (!roleSid) {
    ElMessage.error("请先登录");
    next("/Login");
    return false;
  }

  let rolePaths = []
  switch (roleSid) {
    case 1:
      rolePaths.push('*')
      break;
    default:
      break;
  }
  // admin
  rolePaths[0] == '*' ? next() : next("/Login");

})
// 后置路由守卫
// router.afterEach((to, from) => {
//   console.log(to, from)
// })

export default router;