<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import Header from './components/Header.vue'
export default {
  name: "App",
  components: {
    // Header,
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0px;
  list-style: none;
  outline: none;
  text-decoration: none;
}

.cursor {
  cursor: pointer;
}
</style>
