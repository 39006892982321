export let sign = `9A8E3916DCF9331FC42E5FCEE5813876`; // rsa密钥签名-xcs-2
export let publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAodm8nRaerJw8gYGnMgYq
dvkf6wFXy4+uhlC768+79oZAw+EwUHKBhmhvyrLw7H9OgNNi2gysj4SAWRXKWabz
J27MHxS+h2bXYgzTgpAwDXSxKFa6rt3cBc94Yi1Vpn3qXjzc6wBLWhs6HljrnOsW
THQOLbubHIm+xd1DXUcaxn/L0xnBohGH509B+mXG1TIK4rg/0TtgtjpXPAPA3cgu
LVKUTPPfDqWTAf9MgpoAncKDhyWtW5WDJLMrPfKwmQL7ZibUvBy34QXdk+3jRnhl
24t92UfI40FPoAJnV3xtVTH/1G8mrSeZDFNYKEXE0p5HJQTph8EhP6rAqKcE/oy0
wwIDAQAB
-----END PUBLIC KEY-----`; // rsa公钥
export let privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIIEowIBAAKCAQEAodm8nRaerJw8gYGnMgYqdvkf6wFXy4+uhlC768+79oZAw+Ew
UHKBhmhvyrLw7H9OgNNi2gysj4SAWRXKWabzJ27MHxS+h2bXYgzTgpAwDXSxKFa6
rt3cBc94Yi1Vpn3qXjzc6wBLWhs6HljrnOsWTHQOLbubHIm+xd1DXUcaxn/L0xnB
ohGH509B+mXG1TIK4rg/0TtgtjpXPAPA3cguLVKUTPPfDqWTAf9MgpoAncKDhyWt
W5WDJLMrPfKwmQL7ZibUvBy34QXdk+3jRnhl24t92UfI40FPoAJnV3xtVTH/1G8m
rSeZDFNYKEXE0p5HJQTph8EhP6rAqKcE/oy0wwIDAQABAoIBADeGLgmqH+dRdgRr
yUMFEH5eOs/xVwAzm4jim4Vrb61EI2XPlDaVAdBtdaSRPMu1ZOYXHrt1S6G0sB9f
tVqKRrKJdSdYg4CKDGvLwKfy9Tk4hiozjrm8zOWFZmQv70k9uzB3KsbIBBG2c3rD
wJjFoZG64r5XtP/WVYclwB147juYQjoY/ys1AU8sxjPMgQWjUMQi+rQhAbDdllAQ
/GAbVWdHmRqElr/EaznISXrKBMWJszzt7kE9TpyurSvReblXDE/SYrU25j6CdwTl
7xhqwXVknfNzdMx/P0YgB9rPrCDKwPvU6e9LwaS/u6Rd/ybFDtsQTX9MIRveTk27
GJsKD5ECgYEA1ANurFMcF4mkH69hsvBsd37J+/5SwWr/VCQBiAgl4xPAJkpFegd/
k5jDgegyghMJ6WNx6uODFLn+hYVcRFKagEEr3OeVKJsCJFmgkiyXl5VF5iNxyjy4
7tC2u4wYaUlcKGWyFT/8VZBtWnNcUw4zOZgZ31lT8B52SOjg2i5yXhsCgYEAw24H
DEnA0QusmTed68/cz40EA8Y0bGXwlrMK0E51Sd/jeT9c3XgQtIsXP+HKjKeXcpCK
7Q1aOWERyiP0LBeUGzFiMORTsIF0a4ALTHOSA2+Rw3qQrtgKSbmuRwEJjTxGOkhP
iKOzrz0qopSROmu0WfC3y2+BAs9X8h4+CgqWTnkCgYAnJnMbc0Vuu0YJa9N4vZjq
AAnAdNhHS0DXAscdnZOReSY0a+akgBn44W7I4F3jUyyzYl+45kZgX3A2q8e7/hz1
rhMxebo7g1RD7iEOnRlpLnF36/epRslHoFb+DwK6/ob8Frtp3ST1j/QBjefwlmCz
M/kjG1W6s4VYNVjAMW+BHQKBgQCT1Z+vQ/uAJ3yhStlDkTJQLzRiRoVRmqus1DPR
8Af5okvJZI0zW6gHa1BjGsLGM+D04TK2r+sYjL2QVDJZSFzZVToaEh7c3pDa4Tmq
06IYx0VUwlrAaN0feD7Fp5s6I+ZcIGdJzcwXPAYMhqZ1sxPzFY1zbx4Aqii1FvuH
TQr2wQKBgFZq9ep5pKKILtRu0kBknErWAblPN/vRNtrlZPUR0uvGaFOkEi+HD5GH
jQfaNpyxs3CZ65Mpjw0uQGCP9I8Y50KdeLvsQRZvzLkiZB/93Vleqyu+PrSe3841
hPPNMb6m34jf/fL3moeWnTdHn7t0i6O5fTcWLq8LWzHBgIOr3e0H
-----END RSA PRIVATE KEY-----`; // rsa私钥
export let signatureToken = `C7E3D938A720E7FD8A3F7027DA619C8D`; // 签名令牌-xcs-3