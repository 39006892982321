export default {
  namespaced: true,
  state: {
    // f1表格入参变量
    f1Date: null,
    f2Date: null,
    f3Date: null,
    f4Date: null,
    f5Date: null,
    f6Date: null,
  },
  mutations: {
    setF1Date(state, val) {
      state.f1Date = val
    },
    setF2Date(state, val) {
      state.f2Date = val
    },
    setF3Date(state, val) {
      state.f3Date = val
    },
    setF4Date(state, val) {
      state.f4Date = val
    },
    setF5Date(state, val) {
      state.f5Date = val
    },
    setF6Date(state, val) {
      state.f6Date = val
    }
  }
}